/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, TitleMain, Subtitle, ContactForm, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Zanechat vzkaz"}>
        <Column className="--center pb--60 pt--60" anim={null} name={"wstęp"} animS={null}>
          
          <ColumnWrap className="column__flex js-anim  --anim3 --anim-s5 el--1 pl--20 pr--20 flex--center" anim={"3"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box fs--128 w--300 title-box--invert swpf--uppercase ls--12 lh--12" style={{"maxWidth":""}} content={"Zostaw wiadomość"}>
              </TitleMain>

              <Subtitle className="subtitle-box swpf--uppercase ls--50 lh--16 mt--40" content={"Dziękujemy!"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"kontakt"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 ff--3 fs--18 w--300 lh--2" style={{"maxWidth":660}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Wiadomość","type":"textarea","required":true,"placeholder":"Wpisz tekst wiadomości"},{"name":"Wyślij","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" anim={null} name={"kontakt-2"} animS={null}>
          
          <ColumnWrap className="column__flex el--1 pl--20 pr--20 flex--center" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 swpf--uppercase" innerClassName="pb--06" href={"/pl/"} content={"Wstecz"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}